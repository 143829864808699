import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function LoveLocalOttawaTrexity () {
  const shareTitle = 'Love Local Ottawa feat. Trexity'
  const articleLink = 'https://ottawa.ctvnews.ca/video?clipId=1982002'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Love Local Ottawa interviews Trexity on CTV Morning News</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>June 22, 2020</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/love-local.jpg" alt="Man with box" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>OTTAWA — Love Local interview with Trexity</p>
                <p>Co-owner Alok Ahuja and co-founder Mourad Gazale joins us with more the same day shipping service between cities.</p>
                <a href={articleLink} target="_blank" rel="noreferrer">Watch interview on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/last-mile-solution-picks-up-speed" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Shipping platform Trexity’s ’last-mile’ solution picks up speed with merchants</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
